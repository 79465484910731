import {useRouter} from "next/router";
import {trpc} from "../../../utils/trpc";
import {useIsUserMemberOfProject} from "../../common/auth/authHooks";
import { TLeaderboardStatsProject } from "../routers/projectRouter";
import { timeframeFilterType } from '../types';
import {useWalletFromRouter} from './user';

export function useProjectBySlug() {
  const router = useRouter()
  return trpc.useQuery([
    'project.single',
    { id: router.query.projectId as string },
  ], {
    enabled: !!router.query.projectId
  })
}

export function useProjectStatsBySlug(timeframe: timeframeFilterType) {
  const router = useRouter()
  return trpc.useQuery([
    'project.profile-stats',
    { publicId: router.query.projectId as string, timeframe: timeframe },
  ], {
    enabled: !!router.query.projectId
  })
}

export function useProjectAdminBySlug() {
  const projectId = useProjectId()!
  const {isMember} = useIsUserMemberOfProject(projectId);
  return trpc.useQuery([
    'project.singleAdmin',
    { id: projectId},
  ], {
    enabled: !!projectId && isMember
  })
}

export function useProjectId() {
  const router = useRouter()
  return router.query.projectId as string|undefined;
}

export function useFeaturedProjects() {
  return trpc.useQuery([
    'project.featured.cache',
  ], {
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 12,
  })
}

export function useAllPaginated(page: number) {
  return trpc.useQuery([
    'project.all-paginated',
    { page }
  ])
}

export function useAllProjectsLeaderPaginated(page: number, timeframe: timeframeFilterType) {
  console.log(timeframe);
  
  return trpc.useQuery([
    'project.all-leader-paginated',
    { page, timeframe }
  ]) as {data: TLeaderboardStatsProject[], isFetching: boolean}
}


export function usePlatformStats() {
  return trpc.useQuery([
    'project.platformStats',
  ])
}

export function useAllProjects() {
  return trpc.useQuery([
    'project.all',
  ])
}

export function useProjectsWhereUserIsHolderFromRouter() {
  const wallet = useWalletFromRouter();
  return useProjectsWhereUserIsHolder(wallet);
}

export function useProjectsWhereUserIsHolder(wallet?: string|null) {
  return trpc.useQuery([
    'project.all-user-holder',
    {wallet: wallet ?? ''}
  ], {enabled: !!wallet})
}

export function useProjectsWhereUserIsAdmin() {
  return trpc.useQuery([
    'project.all-user-admin',
  ])
}

export function useProjectCommunityMembers(publicId: string) {
  return trpc.useQuery([
    'project.community-members',
    {publicId}
  ])
}