import { Button, useColorMode } from '@chakra-ui/react'
import React from 'react'
import toast from 'react-hot-toast'
import { useWalletSignInModal } from '../../../components/wallet-ui/useWalletSignInModal'
import { useUser } from '../../common/auth/authHooks'

export const SignMessageToLoginButton: React.FC = () => {
  const { colorMode } = useColorMode()
  const { isAuthed, data: connectedUser } = useUser()
  const isDarkMode = colorMode === 'dark'
  const { isLedger, visible, setVisible } = useWalletSignInModal()

  const signMessage = () => {
    setVisible(true)
    toast.loading('Please sign the message to make changes', {
      duration: 8000,
    })
  }

  return (
    <>
      {isAuthed ? (
        <></>
      ) : (
        <Button
          alignSelf='center'
          marginTop='-20px'
          onClick={() => signMessage()}
          variant={isDarkMode ? 'primaryDark' : 'primary'}
        >
          Sign message to login
        </Button>
      )}
    </>
  )
}
